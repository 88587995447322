import _ from 'lodash';

class Item {
  constructor(key, value) {
    this.key = key;

    if (_.isPlainObject(value)) {
      this.name = value.name;
      this.group = value.group || null;
    } else {
      this.name = value;
      this.group = null;
    }
  }
}

export default class ItemList {
  constructor(items) {
    this.items = Object.fromEntries(
      Object.keys(items).map((key) => [
        key,
        items[key] instanceof Item ? items[key] : new Item(key, items[key])
      ])
    );
  }

  find(key) {
    return this.items[key];
  }

  group() {
    return Object.keys(this.items).reduce((groups, key) => {
      const item = this.items[key];
      const groupName = item.group || '';
      if (!groups[groupName]) {
        groups[groupName] = {};
      }
      groups[groupName][key] = item;
      return groups;
    }, {});
  }

  filter(filter) {
    let items;

    if (!_.isString(filter) || filter.trim() === '') {
      items = this.items;
    } else {
      items = _.pickBy(this.items, (item) => item.name.toLowerCase().includes(filter.toLowerCase().trim()));
    }

    return new ItemList(items);
  }
}