import ConditionSelect from './condition-select';
import Filter from './filter';

export default class extends Filter {
  static get ConditionClass() {
    return ConditionSelect;
  }

  extractConditionValue(data) {
    return this.config.options.find((option) => option.key === data);
  }
}
