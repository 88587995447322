import _ from 'lodash';
import Condition from './condition';

export default class extends Condition {
  constructor() {
    super();
    this.value = [];
  }

  get keys() {
    return _.map(this.value, 'key');
  }

  get name() {
    return this.isPresent() ? _.map(this.value, 'name').join(', ') : null;
  }

  get text() {
    return this.name || '';
  }

  isPresent() {
    return this.value && this.value.length > 0;
  }

  isKeySelected(key) {
    return _.includes(this.keys, key);
  }

  toggleOption(option) {
    if (this.isKeySelected(option.key)) {
      this.value = _.reject(this.value, { key: option.key });
    } else {
      this.value.push(option);
    }
  }

  export() {
    return this.keys;
  }
}
