<template>
  <span :class="colorClass">
    {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    value: { required: true },
    column: { type: Object, required: true }
  },
  computed: {
    colorClass() {
      if (!this.column.colorHighlight || !this.value) {
        return '';
      }

      // value is a string in the form of 0.53% or -0.53% or N/A
      const value = parseFloat(this.value.replace('%', ''));

      if (value > 0) {
        return 'text-green';
      }

      if (value < 0) {
        return 'text-red';
      }

      return '';
    }
  }
};
</script>
