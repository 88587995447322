<template>
  <div v-on-viewport-enter.once="init" class="lazy-load-chart">
    <tabs class="tab-box" :title="title" :type="tabType">
      <tab v-for="(chart, index) in charts"
           :title="chart.title"
           :key="chart.ref"
           :active="index === 0"
           @activated="showChart(chart)">
        <remote-chart :chart-component="chartComponent" :params="chartParams(chart)" :ref="chart.ref"
                      :remote-path="remotePath" :requires-login="chart['requires_login']"></remote-chart>
      </tab>
    </tabs>
  </div>
</template>

<script>
import * as _ from 'lodash';
import RemoteChart from './remote-chart.vue';
import Tabs from '../elements/tabs.vue';
import Tab from '../elements/tab.vue';
import OnViewportEnter from '../../directives/on-viewport-enter';

export default {
  props: {
    title: String,
    tabType: { type: String, default: 'tab' },
    remotePath: { type: String, required: true },
    chartOptions: { required: true },
    chartComponent: { type: String, default: 'chart' }
  },
  methods: {
    init() {
      this.getChartComponent(this.charts[0].ref).init();
    },
    showChart(chart) {
      this.getChartComponent(chart.ref).init();
    },
    buildRef(chart, index) {
      return `${chart.period_type}-${index}`;
    },
    chartParams(chart) {
      return _.omit(chart, ['title', 'ref', 'requires_login']);
    },
    getChartComponent(ref) {
      return this.$refs[ref] ? this.$refs[ref][0] : null;
    }
  },
  computed: {
    charts() {
      const result = _.clone(this.chartOptions);
      result.forEach((chart, index) => {
        chart.ref = this.buildRef(chart, index);
      });
      return result;
    }
  },
  components: { RemoteChart, Tabs, Tab },
  directives: { OnViewportEnter }
};
</script>
