<template>
  <li v-if="showSearch" class="menu-item mb-3">
    <input v-model="search" type="text" class="form-input" placeholder="Search..." @click.stop>
  </li>
  <div class="scrollable-menu-items">
    <template v-for="(groupOptions, groupName) in groupedOptions" :key="groupName">
      <li v-if="groupName" class="menu-item text-uppercase text-gray text-small">{{ groupName }}</li>
      <li v-for="option in groupOptions" :key="option.key" class="menu-item" @click.stop>
        <label class="form-checkbox">
          <input type="checkbox"
                 :value="option.key"
                 :checked="condition.isKeySelected(option.key)"
                 @change="condition.toggleOption(option)">
          <i class="form-icon" />
          {{ option.name }}
        </label>
      </li>
    </template>
  </div>
  <li class="menu-item menu-item-apply">
    <button class="btn btn-primary" :disabled="disabled" @click="applyChanges">
      Apply
    </button>
  </li>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    filter: { required: true, type: Object }
  },
  emits: ['change'],
  data() {
    return {
      condition: this.filter.condition.clone(),
      search: ''
    };
  },
  computed: {
    disabled() {
      return !this.condition.isPresent();
    },
    showSearch() {
      return this.options.length > 15 || this.search !== '';
    },
    options() {
      const filterOptions = this.filter.config.options;

      if (this.search === '') {
        return filterOptions;
      }

      return filterOptions.filter((option) => option.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    groupedOptions() {
      if (_.every(this.options, (option) => !option.group)) {
        return { '': this.options };
      }

      return this.options.reduce((groups, option) => {
        const groupName = option.group || 'Others';
        if (!groups[groupName]) {
          groups[groupName] = [];
        }
        groups[groupName].push(option);
        return groups;
      }, {});
    }
  },
  methods: {
    applyChanges() {
      this.$emit('change', this.condition);
    }
  }
};
</script>
