<template>
  <modal title="Sign up or Log in"
         ref="modal"
         @hide="onHide">
    <div class="text-center">
      <p>{{ message }}</p>
      <a href="/account/sign_up" class="btn btn-primary">Sign up</a>
      <div class="divider" data-content="OR"></div>
      <a href="/account/sign_in" class="btn">Log in</a>
    </div>
  </modal>
</template>

<script>
import Modal from './elements/modal.vue';

export default {
  components: { Modal },
  data() {
    return {
      defaultMessage: 'You need a free account to use this feature',
      message: this.defaultMessage
    };
  },
  methods: {
    show(message) {
      this.message = message || this.defaultMessage;
      this.$refs.modal.show();
    },
    onHide() {
      this.message = this.defaultMessage;
    }
  }
};
</script>
