<template>
  <div>

    <a class="icon icon-star tooltip tooltip-left p-2 ml-2 stock-header-icon c-hand"
       data-tooltip="Add to watchlist"
       @click.prevent="showModal"/>

    <modal v-if="userSignedIn" ref="modal" :title="title">
      <div v-if="dataLoaded">
        <div v-if="this.watchlists.length">
          <div v-for="watchlist in this.watchlists" :key="watchlist.id" class="columns mb-2">
            <div class="column col-10">
              <a :href="watchlist.url">
                {{ watchlist.name }}
              </a>
            </div>
            <div class="column col-2">
              <button v-if="watchlist.has_stock" class="btn btn-success float-right"
                      @click="watchlistWidgetStore.removeStock(watchlist.id, stock)"><i class="icon-check pr-1"></i>Added
              </button>
              <button v-else class="btn btn-primary float-right"
                      @click="watchlistWidgetStore.addStock(watchlist.id, stock)"><i class="icon-plus pr-1"></i>Add
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <p>You don't have any watchlists yet. Create one below.</p>
        </div>
      </div>

      <template v-slot:footer>
        <div v-if="error" class="toast toast-error mt-5 text-left">{{error}}</div>
        <div class="input-group">
          <input type="text" class="form-input" v-model="watchlistWidgetStore.newWatchlistName" placeholder="New watchlist name">
          <button class="btn btn-primary input-group-btn" @click="watchlistWidgetStore.createWatchlist()" :disabled="loading">Create watchlist</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapStores, mapState } from 'pinia';
import Modal from '../elements/modal.vue';
import { useWatchlistWidgetStore } from '../../stores/watchlist-widget-store';

export default {
  components: { Modal },
  props: {
    stock: { type: String, required: true },
    title: { type: String, required: false, default: 'Add stock to watchlist' }
  },
  data() {
    return {};
  },
  methods: {
    showModal() {
      if (this.userSignedIn) {
        this.$refs.modal.show();
        this.watchlistWidgetStore.fill(this.stock);
      } else {
        this.requireLogin();
      }
    }
  },
  computed: {
    ...mapStores(useWatchlistWidgetStore),
    ...mapState(useWatchlistWidgetStore, ['loading', 'error', 'watchlists', 'dataLoaded'])
  },
  watch: {
    loading(value) {
      if (value) {
        this.$refs.modal.showLoading();
      } else {
        this.$refs.modal.hideLoading();
      }
    }
  }
};

</script>
