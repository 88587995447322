export default function formatNumber(num) {
  if (num === null || num === undefined) {
    return 'N/A';
  }

  const format = (n) => {
    const str = n.toFixed(2);
    // Remove trailing zeros
    return str.replace(/\.?0+$/, '');
  };

  if (num >= 1e9) {
    return `${format((num / 1e9))}B`;
  }
  if (num >= 1e6) {
    return `${format((num / 1e6))}M`;
  }
  if (num >= 1e3) {
    return `${format((num / 1e3))}K`;
  }
  return num.toString();
}
