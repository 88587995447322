<template>
  <template v-if="hasPresets && !rangeEditorVisible">
    <li v-for="preset in filter.config.presets" :key="preset.key" class="menu-item">
      <button :class="['btn', 'btn-link', {active: isPresetSelected(preset)}]"
              @click.stop.prevent="applyPreset(preset)">
        {{ preset.name }}
      </button>
    </li>
  </template>
  <li v-if="hasPresets && !rangeEditorVisible" class="menu-item">
    <button :class="['btn', 'btn-link', {active: isRangeSelected}]"
            @click.stop.prevent="maybeShowRangeEditor">
      Custom <i class="icon-arrow-right" />
    </button>
  </li>
  <li v-if="hasPresets && rangeEditorVisible" class="menu-item">
    <button class="btn btn-link" @click.stop.prevent="rangeEditorVisible = false">
      <i class="icon-arrow-left" /> Custom
    </button>
  </li>
  <li v-show="showRangeEditor" class="menu-item" @click.stop.prevent>
    <div class="input-group">
      <span class="input-group-addon">Min</span>
      <input type="number" v-model="condition.min" class="form-input">
    </div>
    <div class="input-group mt-2">
      <span class="input-group-addon">Max</span>
      <input type="number" v-model="condition.max" class="form-input">
    </div>
    <div class="input-group mt-3">
      <button class="btn btn-primary btn-sm" @click="applyRange" :disabled="!condition.isPresent()">Apply</button>
    </div>
  </li>
</template>

<script>

export default {
  props: {
    filter: { required: true, type: Object }
  },
  emits: ['change'],
  data() {
    return {
      condition: this.filter.condition.clone(),
      rangeEditorVisible: false
    };
  },
  computed: {
    hasPresets() {
      return this.filter.config.presets && this.filter.config.presets.length > 0;
    },
    showRangeEditor() {
      return (this.hasPresets && this.rangeEditorVisible) || !this.hasPresets;
    },
    isRangeSelected() {
      return this.filter.condition.isPresent() && !this.filter.condition.isPreset();
    }
  },
  beforeMount() {
    this.rangeEditorVisible = this.filter.condition.isPresent() && !this.filter.condition.isPreset();
  },
  methods: {
    maybeShowRangeEditor() {
      if (this.hasPresets && !this.userSignedIn) {
        this.requireLogin();
      } else {
        this.rangeEditorVisible = true;
      }
    },
    isPresetSelected(preset) {
      return this.filter.condition.isPresetActive(preset);
    },
    applyPreset(preset) {
      this.condition.applyPreset(preset);
      this.$emit('change', this.condition);
    },
    applyRange() {
      this.condition.preset = null;
      this.$emit('change', this.condition);
    }
  }
};

</script>
