<template>
  <span v-html="formattedName"></span>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    name: { type: String, required: true }
  },
  data() {
    return {
      maxLineLength: 15
    };
  },
  computed: {
    /*
     * Formats the name to fit longer names in two lines
     */
    formattedName() {
      const { name } = this;

      if (name.length <= this.maxLineLength || name.indexOf(' ') === -1) {
        return name;
      }

      const wordPositions = name.split(' ').map((part) => name.indexOf(part));
      const middleIndex = Math.floor(name.length / 2);

      // find the word that is closest to the middle of the string
      const closestToMiddle = _.minBy(wordPositions, (position) => Math.abs(position - middleIndex));

      // break the string at the closest word
      const firstPart = name.slice(0, closestToMiddle);
      const secondPart = name.slice(closestToMiddle);

      return `${firstPart}<br/>${secondPart}`;
    }
  }
};
</script>
