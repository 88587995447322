<template>
  <div class="stock-screener-column-config d-inline-block">
    <dropdown-checkboxes v-model="visibleColumnKeys"
                         :items="columnList"
                         position="right"
                         btn-icon="icon-columns"
                         :primary="false"
                         :has-arrow="false"
                         :disabled="loading"
                         :show-search="true"
                         menu-size="sm"
                         @before-toggle="onBeforeToggle"
                         @change="onColumnsChanged" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'pinia';
import { useStockScreenerStore } from '../../stores/stock-screener-store';
import DropdownCheckboxes from '../elements/dropdown-checkboxes.vue';

export default {
  components: {
    DropdownCheckboxes
  },
  data() {
    return {
      store: null,
      visibleColumnKeys: []
    };
  },
  computed: {
    ...mapState(useStockScreenerStore, ['columnList', 'loading'])
  },
  beforeMount() {
    this.store = useStockScreenerStore();
    this.visibleColumnKeys = _.clone(this.store.visibleColumnKeys);
  },
  methods: {
    onBeforeToggle(event) {
      if (!this.userSignedIn) {
        event.preventDefault();
        this.requireLogin();
      }
    },
    onColumnsChanged() {
      if (this.loading) return;
      this.store.setVisibleColumns(this.visibleColumnKeys);
    }
  },
  watch: {
    'store.visibleColumnKeys': function (newVal, oldVal) {
      this.visibleColumnKeys = _.clone(newVal);
    }
  }
};

</script>
