import { createApp } from 'vue';
import axios from 'axios';
import VueScrollTo from 'vue-scrollto';
import { createPinia } from 'pinia';
import { captureException, setTag } from '@sentry/browser';
import { applyAxiosSettings } from './axios';
import VueScreen from '../plugins/screen';

import Tab from '../components/elements/tab.vue';
import Tabs from '../components/elements/tabs.vue';
import RequireLoginModal from '../components/require-login-modal.vue';

const apps = new Map();

const addRequireLoginModal = (elementId, element, app) => {
  const modalAppKey = `${elementId}-login-required-modal`;

  if (apps.has(modalAppKey)) {
    // first unmount existing
    apps.get(modalAppKey).unmount();
  }

  const modalContainer = document.createElement('div');
  element.appendChild(modalContainer);

  const modalApp = createApp(RequireLoginModal);
  app.config.globalProperties.loginRequiredModal = modalApp.mount(modalContainer);
  app.config.globalProperties.requireLogin = (message) => {
    app.config.globalProperties.loginRequiredModal.show(message);
  };

  apps.set(modalAppKey, modalApp);
};

export default function (elementId, instanceOptions, options = {}) {
  document.addEventListener('turbolinks:load', () => {

    const element = document.getElementById(elementId);

    if (element != null) {
      if (apps.has(elementId)) {
        // unmount existing apps for this element
        apps.get(elementId).unmount();
      }

      applyAxiosSettings(axios);

      const VueAxios = {
        install: (app) => {
          app.config.globalProperties.$axios = axios;
        }
      };

      // IMPORTANT: create a clone of the options as otherwise it does not work
      // with turbolinks link navigation because Vue modifies this object -
      // it sets a "template" property and this is applied to the next initialization
      const app = createApp({ ...instanceOptions });

      // plugins
      app.use(VueAxios);
      app.use(VueScreen);
      app.use(createPinia());

      if (options.useVueScrollTo) {
        app.use(VueScrollTo, {
          duration: 400,
          force: true,
          onStart: () => {
            document.querySelector('body').classList.add('nav-transitioning');
          },
          onDone: (el) => {
            el.dispatchEvent(new Event('navDone'));
            document.querySelector('body').classList.remove('nav-transitioning');
          }
        });
      }

      if (APP_ENV === 'production') {
        app.config.errorHandler = (error, _, info) => {
          setTag('info', info);
          captureException(error);
        };
      }

      app.config.globalProperties.userSignedIn = document.querySelector('meta[name="user-signed-in"]').getAttribute('content') === 'true';

      // global components
      app.component('tabs', Tabs);
      app.component('tab', Tab);
      app.component('RequireLoginModal', RequireLoginModal);

      app.mount(`#${elementId}`);
      apps.set(elementId, app);

      // Add RequireLoginModal at the bottom of the element
      addRequireLoginModal(elementId, element, app);
    }
  });
}
