import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

export const applyAxiosSettings = (axiosInstance) => {
  axiosInstance.defaults.headers.common.Accept = 'application/json';
  axiosInstance.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
};

/**
 * Create an axios instance with cache
 * @param methods array of methods to cache, e.g. ['get', 'post']
 */
export const axiosWithCache = (methods) => {
  const axiosInstance = axios.create();
  applyAxiosSettings(axiosInstance);

  return setupCache(axiosInstance, {
    methods,
    interpretHeader: false
  });
};
