import Filter from './filter';
import FilterNumber from './filter-number';
import FilterSelect from './filter-select';
import FilterMultiSelect from './filter-multi-select';

const FILTER_CLASSES = {
  number: FilterNumber,
  select: FilterSelect,
  multi_select: FilterMultiSelect
};

export default function buildFilter(config, conditionData = null) {
  const { type } = config;
  const FilterClass = FILTER_CLASSES[type] || Filter;

  const filter = new FilterClass(config);
  filter.loadCondition(conditionData);

  return filter;
}
