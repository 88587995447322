import Condition from './condition';

export default class extends Condition {
  get key() {
    return this.isPresent() ? this.value.key : null;
  }

  get name() {
    return this.isPresent() ? this.value.name : null;
  }

  get text() {
    return this.name || '';
  }

  isPresent() {
    return this.value && this.value.key != null;
  }

  export() {
    return this.key;
  }
}
