<template>
  <div>
    <div class="modal" :class="{active: active, 'modal-sm': sizeSmall, 'modal-lg': sizeLarge}">
      <a @click.prevent="hide" class="modal-overlay" aria-label="Close"></a>
      <div class="modal-container">
        <div class="modal-header">
          <div class="columns">
            <div v-if="title" class="column col-10 modal-title h5">{{ title }}</div>
            <div class="column col-1">
              <div v-show="isLoading" class="loading float-right"></div>
            </div>
            <div class="column col-1">
              <a @click.prevent="hide" class="btn btn-clear float-right" aria-label="Close"></a>
            </div>

          </div>
        </div>
        <div class="modal-body">
          <div class="content">
            <slot/>
          </div>
        </div>
        <div v-if="hasFooter" class="modal-footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false },
    size: {
      type: String,
      required: false,
      default: 'regular',
      validator(value) {
        return ['small', 'regular', 'large'].includes(value);
      }
    }
  },
emits: ['show', 'hide'],
  data() {
    return {
      active: false,
      defaultClass: 'c-hand',
      isLoading: false
    };
  },
  methods: {
    show() {
      this.$emit('show');
      this.active = true;
    },
    hide() {
      this.$emit('hide');
      this.active = false;
    },
    toggle() {
      this.active = !this.active;
    },
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    }
  },
  computed: {
    sizeSmall() {
      return this.size === 'small';
    },
    sizeLarge() {
      return this.size === 'large';
    },
    hasFooter() {
      return this.$slots.footer !== undefined;
    }
  }
};
</script>
