import Condition from './condition';
import formatNumber from '../../../utility/format-number';

export default class extends Condition {
  constructor() {
    super();
    this.value = { min: null, max: null };
  }

  get min() {
    return this.value.min;
  }

  set min(value) {
    this.value.min = value;
  }

  get max() {
    return this.value.max;
  }

  set max(value) {
    this.value.max = value;
  }

  get minText() {
    return formatNumber(this.min);
  }

  get maxText() {
    return formatNumber(this.max);
  }

  get text() {
    if (this.min !== null && this.max !== null) {
      return `${this.minText} to ${this.maxText}`;
    }

    if (this.min !== null) {
      return `≥ ${this.minText}`;
    }

    if (this.max !== null) {
      return `≤ ${this.maxText}`;
    }

    return '';
  }

  applyPreset(preset) {
    this.preset = preset;
    this.min = this.preset.min;
    this.max = this.preset.max;
  }

  applyRange(min, max) {
    this.preset = null;
    this.min = min === '' ? null : min;
    this.max = max === '' ? null : max;
  }

  isPresent() {
    return this.min !== null || this.max !== null;
  }
}
