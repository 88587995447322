import _ from 'lodash';
import { defineStore } from 'pinia';
import { extractError } from '../utility/remote-error';
import Column from '../models/screener/column';
import ColumnPreset from '../models/screener/column-preset';
import FilterConfig from '../models/screener/filters/filter-config';
import buildFilter from '../models/screener/filters/build-filter';
import { axiosWithCache } from '../utility/axios';

const LOCAL_STORAGE_KEY_PREFIX = 'frStockScreenerState';

export const useStockScreenerStore = defineStore('stockScreenerStore', {
  state: () => ({
    config: {
      id: null,
      filters: [],
      columns: [],
      sort: {},
      perPage: 20
    },
    stocks: [],
    totalCount: 0,
    page: 1,
    columnPresets: [],
    dataLoaded: false,
    loading: false,
    error: null,
    sort: { by: null, direction: null },
    filters: [],
    _axiosWithCache: null
  }),

  getters: {
    stocksPerPage: (state) => state.config.perPage,

    // COLUMNS
    currentColumnPreset: (state) => state.columnPresets.find((preset) => preset.active),
    visibleColumns: (state) => state.currentColumnPreset.columns.map((col) => {
      return state.config.columns.find((c) => c.key === col);
    }),
    visibleColumnKeys: (state) => state.visibleColumns.map((col) => col.key),
    columnList: (state) => Object.fromEntries(
      state.config.columns.map((col) => [col.key, { name: col.name, group: col.group }])
    ),

    // FILTERS
    getFilterConfig: (state) => (key) => state.config.filters.find((filter) => filter.key === key),
    getFilter: (state) => (key) => state.filters.find((filter) => filter.key === key),
    appliedFilters: (state) => state.filters.filter((filter) => filter.applied),
    hasUnappliedFilters: (state) => state.filters.some((filter) => !filter.applied),
    filterList: (state) => {
      const allFilters = Object.fromEntries(
        state.config.filters.map((filter) => [filter.key, { name: filter.name, group: filter.group }])
      );

      // Return filters that are not already applied
      return _.omit(allFilters, state.filters.map((filter) => filter.key));
    },

    // LOCAL STORAGE
    _shouldUseLocalStorage: (state) => state.config.id === 'default',
    _sessionStorageKey: (state) => `${LOCAL_STORAGE_KEY_PREFIX}_${state.config.id}`
  },

  actions: {
    init(config) {
      this._axiosWithCache = axiosWithCache(['post']);

      this.config = {
        ...this.config,
        ...config
      };

      this.config.columns = this.config.columns.map((col) => new Column({
        ...col,
        defaultSortDirection: col.default_sort_direction,
        colorHighlight: col.color_highlight
      }));

      this.columnPresets = this.config.column_presets.map((preset) => new ColumnPreset(preset));
      this.columnPresets[0].active = true;

      this.config.filters = this.config.filter_configs.map((filter) => new FilterConfig(filter));
      this.filters = this._extractAppliedFilters(this.config.applied_filters);

      this.sort = config.sort;

      if (this._shouldUseLocalStorage) {
        this._loadState();
      }

      this.fill();
    },
    activateColumnPreset(preset) {
      if (this.loading) return;

      this.columnPresets.forEach((p) => { p.active = false; });
      preset.active = true;

      this._maybeSaveState();
      this.fill();
    },
    fill(resetPage = false) {
      this._beforeAction();
      this._maybeSaveState();

      if (resetPage) {
        this.page = 1;
      }

      this._axiosWithCache.post('/stock-screener/stocks', this._params())
        .then((response) => {
          this.stocks = response.data.stocks;
          this.totalCount = response.data.total_count;
          this.dataLoaded = true;
        })
        .catch(this._handleError)
        .finally(() => { this.loading = false; });
    },

    sortBy(col) {
      if (this.loading) return;

      if (this.sort.by === col.key) {
        this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.direction = col.defaultSortDirection;
      }

      this.sort.by = col.key;

      this._maybeSaveState();
      this.fill(true);
    },

    setVisibleColumns(keys) {
      if (this.loading) return;

      const oldKeys = this.currentColumnPreset.columns;

      // if there are new columns added, we need to fetch the data again
      const newColumnsAdded = _.difference(keys, oldKeys).length > 0;
      this.currentColumnPreset.columns = keys;

      this._maybeSaveState();
      if (newColumnsAdded) {
        this.fill();
      }
    },

    setColumnOrder(keys) {
      this.currentColumnPreset.columns = keys;
      this._maybeSaveState();
    },

    addFilter(filter) {
      if (this.loading) return;

      this.filters.push(filter);
    },

    createFilter(key) {
      if (this.loading) return;

      const filter = buildFilter(this.getFilterConfig(key));
      this.addFilter(filter);
    },

    updateFilter(filter, condition) {
      if (this.loading) return;

      filter.applyCondition(condition.clone());

      this._maybeSaveState();
      this.fill(true);
    },

    removeFilter(key) {
      if (this.loading) return;

      const filter = this.getFilter(key);
      const index = this.filters.indexOf(filter);
      this.filters.splice(index, 1);

      if (filter.applied) {
        this._maybeSaveState();
        this.fill(true);
      }
    },

    removeAllFilters() {
      if (this.loading) return;

      this.filters = [];

      this._maybeSaveState();
      this.fill(true);
    },

    changePage(page) {
      if (this.loading) return;

      this.page = page;

      this._maybeSaveState();
      this.fill(false);
    },

    _beforeAction() {
      this.loading = true;
      this.error = null;
      this.error_code = null;
    },

    _handleError(error) {
      this.error = {
        message: extractError(error),
        status: error.response.status
      };
    },

    _params() {
      return {
        visible_columns: this.visibleColumnKeys,
        per_page: this.stocksPerPage,
        page: this.page,
        sort_by: this.sort.by,
        sort_direction: this.sort.direction,
        filters: this.appliedFilters.map((filter) => filter.export())
      };
    },

    _extractAppliedFilters(appliedFilters) {
      // eslint-disable-next-line max-len
      return appliedFilters.map((appliedFilter) => buildFilter(this.getFilterConfig(appliedFilter.key), appliedFilter.condition));
    },

    _maybeSaveState() {
      if (this._shouldUseLocalStorage && this.dataLoaded) {
        this._saveState();
      }
    },

    _saveState() {
      const stateToSave = {
        columnPresets: this.columnPresets,
        filters: this.appliedFilters.map((filter) => filter.export()),
        sort: this.sort,
        page: this.page
      };

      sessionStorage.setItem(this._sessionStorageKey, JSON.stringify(stateToSave));
    },

    _loadState() {
      const state = sessionStorage.getItem(this._sessionStorageKey);
      if (state) {
        const parsedState = JSON.parse(state);
        this.filters = this._extractAppliedFilters(parsedState.filters);
        this.columnPresets = parsedState.columnPresets;
        this.sort = parsedState.sort;
        this.page = parsedState.page;
      }
    }
  }
});
