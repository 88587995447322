import _ from 'lodash';
import ConditionMultiSelect from './condition-multi-select';
import Filter from './filter';

export default class extends Filter {
  static get ConditionClass() {
    return ConditionMultiSelect;
  }

  extractConditionValue(data) {
    return _.filter(this.config.options, (option) => _.includes(data, option.key));
  }
}
