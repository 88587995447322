<template>
  <div class="stock-screener" :class="{'stock-screener-loading': loading}">
    <stock-screener-filters class="ml-2 mt-7" />
    <div class="pl-5 pr-5">
      <div v-if="error"
           :class="['toast mt-3', error.status === 401 ? 'toast-primary' : 'toast-error']">
        {{ error.message }}
      </div>
    </div>
    <div v-if="!error" class="container">
      <div class="columns ml-5">
        <div class="column col-1 flex-centered">
          <div v-show="showSmallLoading" class="loading"></div>
        </div>
      </div>
      <div class="container">
        <div class="columns stock-screener-columns-wrap">
          <div class="column col-auto col-mr-auto">
            <span class=" text-gray stock-screener-count">
              <span class="text-bold text-dark">{{ totalCount }}</span> stocks
            </span>
          </div>
          <div class="column col-auto col-ml-auto">
            <tabs :disabled="loading">
              <tab v-for="preset in columnPresets"
                   :key="preset.id"
                   :title="preset.name"
                   :active="preset.active"
                   @activated="store.activateColumnPreset(preset)" />
            </tabs>
          </div>
          <div class="column col-auto">
            <stock-screener-column-config class="mt-1" />
          </div>
        </div>
      </div>
      <div class="stock-screener-table-container">
        <table class="table table-striped stock-screener-table">
          <thead>
            <tr ref="tableHeader">
              <th>Ticker</th>
              <th>Name</th>
              <th v-for="col in visibleColumns"
                  :key="col.key"
                  :class="{'c-hand': col.sortable}"
                  class="draggable"
                  @click="maybeSort(col)">
                <column-name :name="col.name" />
                <span v-if="sort.by === col.key">
                  <span v-if="sort.direction === 'asc'" class="sort-arrow">&uarr;</span>
                  <span v-if="sort.direction === 'desc'" class="sort-arrow">&darr;</span>
                </span>

                <i v-if="!loading"
                   class="column-drag-handle icon icon-more-vertical c-move-horizontal" />
              </th>
            </tr>
          </thead>
          <tbody v-if="dataLoaded">
            <tr v-for="stock in stocks" :key="stock.id">
              <td>
                <a :href="stock.link">
                  <span class="ticker">{{ stock.ticker }}</span>
                </a>
              </td>
              <th>
                <a :href="stock.link">
                  {{ stock.name }}
                </a>
              </th>
              <td v-for="col in visibleColumns" :key="col.key">
                <column-value :value="stock[col.key]" :column="col" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <empty-state v-if="noResultsFound"
                   icon="search"
                   title="No results found" />

      <div v-if="showLargeLoading" class="loading loading-lg mt-9 stock-screener-table-loading" />

      <pagination :total-count="totalCount"
                  :per-page="stocksPerPage"
                  :page="page"
                  @page-change="store.changePage"
                  class="ml-5" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import Sortable from 'sortablejs';
import { useStockScreenerStore } from '../../stores/stock-screener-store';
import Tabs from '../elements/tabs.vue';
import Pagination from '../elements/pagination.vue';
import ColumnName from './stock-screener-column-name.vue';
import ColumnValue from './stock-screener-column-value.vue';
import EmptyState from '../elements/empty-state.vue';
import StockScreenerFilters from './stock-screener-filters.vue';
import StockScreenerColumnConfig from './stock-screener-column-config.vue';

export default {
  components: {
    Tabs, Pagination, ColumnName, ColumnValue, EmptyState, StockScreenerFilters, StockScreenerColumnConfig
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store: null
    };
  },
  computed: {
    ...mapState(useStockScreenerStore, ['loading', 'error', 'error_code', 'stocks', 'dataLoaded',
      'columnPresets', 'sort', 'totalCount', 'page', 'stocksPerPage', 'visibleColumns']),
    showSmallLoading() {
      return this.loading && this.dataLoaded;
    },
    showLargeLoading() {
      return this.loading && !this.dataLoaded;
    },
    noResultsFound() {
      return this.dataLoaded && this.stocks.length === 0;
    }
  },
  beforeMount() {
    this.store = useStockScreenerStore();
    this.store.init(this.config);
  },
  mounted() {
    this.initSortable();
  },
  methods: {
    maybeSort(col) {
      if (!this.userSignedIn) {
        this.requireLogin();
      } else if (col.sortable) {
        this.store.sortBy(col);
      }
    },
    initSortable() {
      const el = this.$refs.tableHeader;
      Sortable.create(el, {
        direction: 'horizontal',
        animation: 150,
        onEnd: this.onDragEnd,
        draggable: '.draggable',
        handle: '.column-drag-handle'
      });
    },
    onDragEnd(evt) {
      const stickyColumns = 2;
      const newOrder = [...this.visibleColumns];
      const movedItem = newOrder.splice(evt.oldIndex - stickyColumns, 1)[0];
      newOrder.splice(evt.newIndex - stickyColumns, 0, movedItem);
      this.store.setColumnOrder(newOrder.map((col) => col.key));
    }
  }
};

</script>
