<template>
  <li v-for="option in filter.config.options" :key="option.key" class="menu-item">
    <button class="btn btn-link" :class="{active: condition.key === option.key}"
            @click.stop.prevent="select(option)">
      {{ option.name }}
    </button>
  </li>
</template>

<script>

export default {
  props: {
    filter: { required: true, type: Object }
  },
  emits: ['change'],
  data() {
    return {
      condition: this.filter.condition.clone()
    };
  },
  methods: {
    select(option) {
      this.condition.apply(option);
      this.$emit('change', this.condition);
    }
  }
};

</script>
