<template>
  <div class="empty">
    <div v-if="icon" class="empty-icon">
      <i :class="iconClass"/>
    </div>
    <p v-if="title" class="empty-title h5">
      {{ title }}
    </p>
    <p v-if="subtitle" class="empty-subtitle">
      {{ subtitle }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: null },
    iconSize: {type: String, default: '3x' },
    title: { type: String, default: null },
    subtitle: { type: String, default: null }
  },
  computed: {
    iconClass() {
      return `icon-${this.iconSize} icon-${this.icon}`;
    }
  }
};
</script>
