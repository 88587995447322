<template>
  <div>
    <div :class="['dropdown dropdown-js', {'active': visible, 'dropdown-right': position==='right'}]">

      <dropdown-button :btn-text="btnText"
                       :has-arrow="hasArrow"
                       :primary="primary"
                       :disabled="disabled"
                       :btn-icon="btnIcon"
                       @toggle="toggleDropdown">
        <ul class="menu" :class="menuClass">
          <li v-if="showSearch" class="menu-item mb-3 mt-3">
            <input v-model="search" type="text" class="form-input" placeholder="Search..." @click.stop>
          </li>
          <div class="scrollable-menu-items">
            <template v-for="(groupItems, groupName) in displayItems" :key="groupName">
              <li v-if="groupName" class="menu-item text-uppercase text-gray text-tiny">{{ groupName }}</li>
              <li v-for="(item, key) in groupItems" :key="key" class="menu-item" @click.stop>
                <label class="form-checkbox">
                  <input type="checkbox"
                         :value="key"
                         :checked="isSelected(key)"
                         :disabled="disabled"
                         @click="onCheckboxClick"
                         @change="toggleSelection(key)">
                  <i class="form-icon" />
                  {{ item.name }}
                </label>
              </li>
            </template>
          </div>
          <li class="menu-item pt-1">
            <button class="btn btn-primary btn-sm " :disabled="disabled" @click="applyChanges">
              Apply
            </button>
          </li>
        </ul>
      </dropdown-button>
    </div>
  </div>
</template>

<script>
import ItemList from '../../utility/item-list';
import DropdownButton from './dropdown-button.vue';

export default {
  components: { DropdownButton },
  props: {
    modelValue: { type: Array, required: true },
    items: { type: Object, required: true },
    position: { type: String, default: 'left' },
    btnText: { type: String, default: null },
    btnIcon: { type: String, default: null },
    hasArrow: { type: Boolean, default: true },
    primary: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    menuSize: { type: String, default: 'xs' },
    showSearch: { type: Boolean, default: false }
  },
  emits: ['change', 'update:modelValue', 'beforeToggle'],
  data() {
    return {
      visible: false,
      selectedKeys: [...this.modelValue],
      search: '',
      itemList: new ItemList(this.items)
    };
  },
  computed: {
    menuClass() {
      return `menu-size-${this.menuSize}`;
    },
    displayItems() {
      return this.itemList.filter(this.search).group();
    }
  },
  methods: {
    toggleDropdown() {
      if (this.disabled) return;

      this.visible = !this.visible;
    },
    applyChanges() {
      if (this.disabled) return;

      this.$emit('update:modelValue', this.selectedKeys);
      this.$emit('change', this.selectedKeys);
      this.visible = false;
    },
    isSelected(key) {
      return this.selectedKeys.includes(key);
    },
    onCheckboxClick(event) {
      this.$emit('beforeToggle', event);
    },
    toggleSelection(key) {
      if (this.disabled) return;

      const index = this.selectedKeys.indexOf(key);
      if (index > -1) {
        this.selectedKeys.splice(index, 1);
      } else {
        this.selectedKeys.push(key);
      }
    }
  },
  watch: {
    modelValue(newVal) {
      this.selectedKeys = [...newVal];
    }
  }
};
</script>
