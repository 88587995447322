import Condition from './condition';

export default class Filter {
  constructor(config, condition, applied) {
    this.key = config.key;
    this.config = config;
    this.condition = condition;
    this.applied = applied;
  }

  static get ConditionClass() {
    return Condition;
  }

  apply() {
    this.applied = true;
  }

  applyCondition(condition) {
    this.condition = condition;
    this.apply();
  }

  isNumberType() {
    return this.config.type === 'number';
  }

  isSelectType() {
    return this.config.type === 'select';
  }

  isMultiSelectType() {
    return this.config.type === 'multi_select';
  }

  export() {
    return {
      key: this.config.key,
      condition: this.condition.export()
    };
  }

  loadCondition(conditionData) {
    const { ConditionClass } = this.constructor;
    this.condition = new ConditionClass();

    if (conditionData !== null) {
      this.condition.apply(this.extractConditionValue(conditionData));
      this.apply();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  extractConditionValue(data) {
    return data;
  }
}
