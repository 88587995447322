import _ from 'lodash';

export default class {
  constructor() {
    this.preset = null;
    this.value = null;
  }

  get text() {
    return this.isPresent() ? this.value : '';
  }

  apply(value) {
    this.value = value;
  }

  applyPreset(preset) {
    this.preset = preset;
    this.value = preset;
  }

  isPreset() {
    return this.preset !== null;
  }

  isPresetActive(preset) {
    return _.isEqual(this.preset, preset);
  }

  isPresent() {
    return this.value != null;
  }

  export() {
    return this.value;
  }

  clone() {
    // Create a new instance with the same prototype and assign all properties from this instance
    return Object.assign(Object.create(Object.getPrototypeOf(this)), _.cloneDeep(this));
  }
}
