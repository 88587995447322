<template>
  <div :class="{'btn-group': hasArrow}" @click="toggleDropdown">
    <button class="btn"
            :class="{'btn-primary': primary, 'btn-with-text': btnText, 'btn-no-text': !btnText}"
            :disabled="disabled">
      <i v-if="btnIcon && btnIconPosition==='left'" :class="iconClass" />
      {{ btnText }}
      <i v-if="btnIcon && btnIconPosition==='right'" :class="iconClass" />
    </button>
    <button v-if="hasArrow"
            class="btn dropdown-toggle"
            :class="{'btn-primary': primary}"
            :disabled="disabled">
      <i class="icon-caret" />
    </button>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    btnText: { type: String, default: null },
    btnIcon: { type: String, default: null },
    btnIconPosition: { type: String, default: 'left' },
    hasArrow: { type: Boolean, default: true },
    primary: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  computed: {
    iconClass() {
      return [this.btnIcon, { 'mr-1': this.btnText }];
    }
  },
  methods: {
    toggleDropdown(event) {
      if (!this.disabled) {
        this.$emit('toggle', event);
      }
    }
  }
};
</script>
