<template>
  <div v-click-outside="close"
       class="stock-screener-filter"
       :class="['dropdown dropdown-js dropdown-righ', {active: isOpened}]">
    <div class="btn-group" @click="toggleDropdown">
      <a class="btn btn-outline-white">{{ label }}</a>
      <a class="btn btn-outline-white dropdown-toggle" @click="remove">
        <i class="icon-x" />
      </a>
      <ul v-show="isOpened" class="menu">
        <stock-screener-filter-number v-if="filter.isNumberType()"
                                      :filter="filter"
                                      @change="onChange" />

        <stock-screener-filter-select v-if="filter.isSelectType()"
                                      :filter="filter"
                                      @change="onChange" />

        <stock-screener-filter-multi-select v-if="filter.isMultiSelectType()"
                                            :filter="filter"
                                            @change="onChange" />
      </ul>
    </div>
  </div>
</template>

<script>
import StockScreenerFilterNumber from './stock-screener-filter-number.vue';
import StockScreenerFilterSelect from './stock-screener-filter-select.vue';
import StockScreenerFilterMultiSelect from './stock-screener-filter-multi-select.vue';
import ClickOutside from '../../directives/click-outside';

export default {
  components: {
    StockScreenerFilterNumber,
    StockScreenerFilterSelect,
    StockScreenerFilterMultiSelect
  },
  directives: {
    ClickOutside
  },
  props: {
    filter: { type: Object, required: true },
    opened: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ['change', 'remove'],
  data() {
    return {
      isOpened: this.opened
    };
  },
  computed: {
    label() {
      return `${this.filter.config.name} ${this.filter.condition.text}`;
    }
  },
  methods: {
    onChange(condition) {
      if (this.disabled) return;

      this.isOpened = false;
      this.$emit('change', condition);
    },
    remove() {
      if (this.disabled) return;

      this.$emit('remove');
    },
    toggleDropdown() {
      if (this.disabled) return;

      this.isOpened = !this.isOpened;
    },
    close() {
      this.isOpened = false;
    }
  }
};
</script>
