export default class {
  constructor({ key, name, visible, sortable, group, defaultSortDirection, colorHighlight }) {
    this.key = key;
    this.name = name;
    this.visible = visible;
    this.sortable = sortable;
    this.group = group;
    this.defaultSortDirection = defaultSortDirection;
    this.colorHighlight = colorHighlight;
  }
}
