<template>
  <div>
    <div :class="['dropdown dropdown-js', {'active': visible, 'dropdown-right': position==='right'}]">
      <dropdown-button :btn-text="selectedName"
                       :has-arrow="hasArrow"
                       :primary="false"
                       :disabled="disabled"
                       @toggle="toggleDropdown">
        <ul class="menu">
          <li v-for="(name, index) in items" :key="name" class="menu-item">
            <button :class="['btn', 'btn-link', {'active': (index === modelValue)}]"
                    @click.stop.prevent="selectItem(index)">
              {{ name }}
            </button>
          </li>
        </ul>
      </dropdown-button>
    </div>
  </div>
</template>

<script>

import DropdownButton from './dropdown-button.vue';

export default {
  name: 'DropdownComponent',
  components: { DropdownButton },
  props: {
    modelValue: { required: true },
    items: { type: [Array, Object], required: true },
    position: { type: String, default: 'left' },
    defaultText: { type: String, default: 'Select' },
    hasArrow: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  emits: ['change', 'update:modelValue'],
  data() {
    return {
      visible: false
    };
  },
  computed: {
    selectedName() {
      return this.modelValue != null ? this.items[this.modelValue] : this.defaultText;
    }
  },
  methods: {
    toggleDropdown() {
      this.visible = !this.visible;
    },
    selectItem(key) {
      if (this.disabled) return;
      this.$emit('update:modelValue', key);
      this.$emit('change', key);
      this.visible = false;
    }
  }
};
</script>
